@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply flex grow flex-col;
}

body {
  @apply m-0 font-sans font-normal text-black;
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}
