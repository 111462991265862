@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('assets/fonts/RobotoMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('assets/fonts/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('assets/fonts/RobotoMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
